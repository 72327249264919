import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {
  HomeIcon,
  Users2Icon,
  ImageIcon,
  Share2Icon,
  WalletIcon,
  BellIcon,
  TrashIcon,
  LayoutGridIcon,
  DollarSignIcon,
} from 'lucide-react';
import { useBreakpoint } from "@/hooks/useBreakpoint";
import { useLayoutStore } from "@/store/useLayoutStore";
import { SettingOutlined } from '@ant-design/icons';

interface NavItem {
  title: string;
  href: string;
  icon: React.ReactNode;
  variant?: "default" | "ghost";
}

const items: NavItem[] = [
  {
    title: "Home",
    href: "/",
    icon: <HomeIcon className="w-4 h-4" />,
    variant: "default",
  },
  {
    title: "Bhandaras",
    href: "/bhandaras",
    icon: <LayoutGridIcon className="w-4 h-4" />,
  },
  {
    title: "Users",
    href: "/users",
    icon: <Users2Icon className="w-4 h-4" />,
  },
  {
    title: "Posts",
    href: "/posts",
    icon: <ImageIcon className="w-4 h-4" />,
  },
  {
    title: "Community",
    href: "/community",
    icon: <Share2Icon className="w-4 h-4" />,
  },
  {
    title: "Wallet",
    href: "/wallet",
    icon: <WalletIcon className="w-4 h-4" />,
  },
  {
    title: "Banner",
    href: "/banner",
    icon: <ImageIcon className="w-4 h-4" />,
  },
  {
    title: "Credit Points",
    href: "/refral-credit",
    icon: <DollarSignIcon className="w-4 h-4" />,
  },
  {
    title: "Announcement",
    href: "/announcement",
    icon: <BellIcon className="w-4 h-4" />,
  },
  {
    title: "Delete Requests",
    href: "/delete-requests",
    icon: <TrashIcon className="w-4 h-4" />,
  },
  {
    title: "System Settings",
    href: "/system-settings",
    icon: <SettingOutlined className="w-4 h-4" />,
  },
];

interface MainNavProps {
  isCollapsed: boolean;
}

export function MainNav({ isCollapsed }: MainNavProps) {
  const { pathname } = useLocation();
  const isMobile = useBreakpoint({ end: 'TABLET', operation: 'down' });
  const { setSidebarOpen } = useLayoutStore();

  const handleNavClick = () => {
    if (isMobile) {
      setSidebarOpen(false);
    }
  };

  return (
    <div className={cn(
      "flex flex-col py-2",
      isCollapsed ? "px-2" : "px-4",
      "transition-all duration-300 ease-in-out"
    )}>
      <nav className="space-y-1">
        <TooltipProvider>
          {items.map((item, index) => {
            const isActive = pathname === item.href;
            return (
              <Tooltip key={index} delayDuration={200}>
                <TooltipTrigger asChild>
                  <Link
                    to={item.href}
                    onClick={handleNavClick}
                    className={cn(
                      buttonVariants({ variant: item.variant || "ghost" }),
                      "relative w-full h-10",
                      "transition-all duration-200",
                      isCollapsed ? "justify-center px-2" : "justify-start px-3",
                      isActive ? "bg-[#FF8000] text-white hover:bg-[#FF8000]" : "text-gray-300 hover:text-white hover:bg-gray-800/50",
                      "overflow-hidden"
                    )}
                  >
                    <span className={cn(
                      "flex items-center min-w-0",
                      isCollapsed ? "justify-center" : "justify-start gap-3"
                    )}>
                      {item.icon}
                      {!isCollapsed && (
                        <span className="truncate text-sm">
                          {item.title}
                        </span>
                      )}
                    </span>
                    {isActive && !isCollapsed && (
                      <div className="absolute right-2 top-1/2 h-2 w-2 -translate-y-1/2 rounded-full bg-white animate-pulse" />
                    )}
                  </Link>
                </TooltipTrigger>
                {isCollapsed && (
                  <TooltipContent
                    side="right"
                    className="flex items-center border-none bg-[#FF8000] text-white font-medium shadow-[0_2px_8px_rgba(0,0,0,0.3)]"
                    sideOffset={8}
                  >
                    <p className="max-w-[200px] truncate text-sm">{item.title}</p>
                  </TooltipContent>
                )}
              </Tooltip>
            );
          })}
        </TooltipProvider>
      </nav>
    </div>
  );
}
