import { ScrollArea } from '@/components/ui/scroll-area';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { cn } from '@/lib/utils';
import useAuthStore from '@/store/useAuthStore';
import { useLayoutStore } from '@/store/useLayoutStore';
import { Loader2 } from 'lucide-react';
import React, { Suspense, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import ProtectedRoute from '../auth/ProtectedRoute';
import { Header } from './Header';
import { MainNav } from './MainNav';

const LoadingFallback = () => (
  <div className="h-[calc(100vh-4rem)] flex flex-col items-center justify-center gap-4">
    <Loader2 className="h-10 w-10 animate-spin text-primary" />
    <p className="text-sm text-muted-foreground animate-pulse">Loading...</p>
  </div>
);

const ProtectedLayout: React.FC = () => {
  const { isAuthenticated } = useAuthStore();
  const { isSidebarOpen, setSidebarOpen } = useLayoutStore();
  const isMobile = useBreakpoint({ end: 'TABLET', operation: 'down' });

  useEffect(() => {
    setSidebarOpen(!isMobile);
  }, [isMobile, setSidebarOpen]);


  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="flex min-h-screen bg-gray-50">
      {/* Sidebar */}
      <aside
        className={cn(
          "fixed left-0 top-0 z-40",
          "h-full bg-gray-900",
          "transition-all duration-300",
          isMobile ? (
            isSidebarOpen ? "w-[280px]" : "w-0 -translate-x-full"
          ) : (
            isSidebarOpen ? "w-64" : "w-14"
          )
        )}
      >
        <ScrollArea className={cn(
          "flex h-full w-full flex-col",
          isMobile && !isSidebarOpen && "hidden"
        )}>
          <div className={cn(
            "flex h-16 items-center justify-between border-b border-gray-800",
            "transition-all duration-300",
            isSidebarOpen ? "px-4" : "px-2",
            isMobile && !isSidebarOpen && "opacity-0"
          )}>

            <img
              src="/images/logo.png"
              alt="Logo"
              className="h-8 w-auto transition-transform duration-200 hover:scale-105"
            />

          </div>
          <div className={cn(
            "h-[calc(100vh-4rem)]",
            "transition-all duration-300",
            isSidebarOpen ? "px-4" : "px-2",
            "py-6"
          )}>
            <MainNav isCollapsed={!isSidebarOpen} />
          </div>
        </ScrollArea>
      </aside>

      {/* Mobile Overlay */}
      {isMobile && isSidebarOpen && (
        <div
          className="fixed inset-0 z-30 bg-black/50"
          onClick={() => setSidebarOpen(false)}
        />
      )}

      {/* Main Content */}
      <div className={cn(
        "flex-1 flex flex-col min-h-screen bg-white",
        "transition-all duration-300",
        isMobile && isSidebarOpen ? "opacity-25" : "opacity-100",
        isSidebarOpen && !isMobile ? "ml-64" : "ml-14",
        isMobile && "ml-0"
      )}>
        <Header />
        <main className={cn(
          "flex-1 transition-all duration-300",
          "p-4 md:p-6 lg:p-8",
          "flex flex-col items-center",
          "bg-[#f5f5f5]",
          "w-full"
        )}>
          <div className={cn(
            "w-full",
            "max-w-[1200px]",
            "mx-auto"
          )}>
            <Suspense fallback={<LoadingFallback />}>
              <Outlet />
            </Suspense>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ProtectedLayout;
